
import {defineComponent, ref} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {TeamSelect} from "@/core/composite/composite";
import UserService from "@/core/services/UserService";

export default defineComponent({
  name: "AddTeamForm",
  components: {BaseSelect, BaseForm},
  props: {
    userId: {type: String, required: true},
  },
  setup() {
    const submitting = ref(false);
    const model = ref('');
    return {
      model,
      submitting,
      ...TeamSelect(),
    }
  },
  emits: ['saved'],
  methods: {
    onSubmit() {
      this.submitting = true;
      UserService.setTeam(this.userId, this.model).then(res => {
        this.$emit('saved', res);
        this.model = '';
      }).finally(() => {
        this.submitting = false;
      })
    }
  }
})
