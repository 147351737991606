
import {defineComponent, ref} from 'vue'
import UserService from "@/core/services/UserService";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import AddTeamForm from "@/views/user/AddTeamForm.vue";
import Swal from "sweetalert2";

export default defineComponent({
  name: "Teams",
  components: {AddTeamForm, BaseModal, QuickAction},
  props: {
    userId: {type: String, required: true},
  },
  setup(props) {
    const teams = ref<any>([]);
    const loading = ref(true);
    const loadData = async (teamId: any) => {
      loading.value = true;
      UserService.getTeams(teamId).then(res => {
        teams.value = res;
      }).finally(() => {
        loading.value = true;
      })
    }
    loadData(props.userId);
    return {
      teams,
      loadData,
    }
  },
  methods: {
    onSaved() {
      this.loadData(this.userId);
      const modal = this.$refs.formAddTeamRef as typeof BaseModal;
      modal.hideBaseModal();
    },
    onAdd() {
      const modal = this.$refs.formAddTeamRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await UserService.deleteTeam(this.userId, id).then(() => {
            this.loadData(this.userId);
          })
        }
      })
    }
  }
})
